exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beta-index-tsx": () => import("./../../../src/pages/beta/index.tsx" /* webpackChunkName: "component---src-pages-beta-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-social-index-tsx": () => import("./../../../src/pages/social/index.tsx" /* webpackChunkName: "component---src-pages-social-index-tsx" */),
  "component---src-templates-projects-details-projects-details-template-tsx": () => import("./../../../src/templates/projects-details/projects-details.template.tsx" /* webpackChunkName: "component---src-templates-projects-details-projects-details-template-tsx" */)
}

